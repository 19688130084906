<script>
import DataService from "@/services/data";
import TextService from "@/services/text";
import Catalog from "@/components/Catalog.vue";
import InfiniteLoading from 'vue-infinite-loading';
import config from "@/config";

import {SlideXLeftTransition, SlideYUpTransition } from 'vue2-transitions'

export default {
	name: "SearchResult",
	components: {
		Catalog,
        InfiniteLoading,

        SlideXLeftTransition,
        SlideYUpTransition,
	},
    props: {
	},
	data() {
		return {
			debug: config.debug,
            infiniteScroll: true, // toggle infinite scroll here

            status: null,
            loading: null,
			data: [],

            page: 1,
			pageSize: 30, // define page size limit here
			paginatedSize: 0,
            total: 0,
			totalPages: 0,

            // scrollPage: 1,

			sort: 'collector_number',
			order: 'ASC',

			sortOptions: ['collector_number', 'name', 'cost_memory', 'cost_reserve', 'rarity'],

            queryString: '', // used as key to make child component (catalog) re-renders when the data changes, not intend to be an actual queryString
		};
	},
    methods: {
        getCards(query) {
            DataService.searchCards(query)
                .then((response) => {
					this.status = response.status;

					if (response.status === 200) {
						this.data = response.data.data;

						this.total = response.data.total_cards;
						this.totalPages = response.data.total_pages;
						this.paginatedSize = response.data.paginated_cards_count;
						this.page = response.data.page;

						this.sort = response.data.sort;
						this.order = response.data.order;
					}
					

                    this.queryString = new URLSearchParams(query).toString();
                    
                    this.loading = false;
				})
				.catch((e) => {
					console.log(e);
				});
        },
		paginationLinkGen(page) {
			const combinedQuery = {
				...this.$route.query,
				page
			}
			return {
				name: 'SearchResult',
				query: combinedQuery
			}
		},
		changeSortName(name) {
			// console.log(`changeSort: ${name}`)
			const combinedQuery = {
				...this.$route.query,
				sort: name,
                page: 1
			}
			this.$router.push({ name: 'SearchResult',  query: combinedQuery  })
		},
		changeSortOrder(order) {
			const combinedQuery = {
				...this.$route.query,
				order,
                page: 1
			}
			this.$router.push({ name: 'SearchResult',  query: combinedQuery  })
		},
		getSortIcon (order) {
			// return the proper icon for the selected sorting column data type.
			// direction: 'up' or 'down'
			const string = ['name']
			const number = ['collector_number', 'cost_memory', 'cost_reserve', 'rarity']
			const direction = order === 'ASC' ? 'down' : 'down-alt'

			if (string.indexOf(this.sort) > - 1) return `sort-alpha-${direction}`
			if (number.indexOf(this.sort) > -1) return `sort-numeric-${direction}`

			return `sort-${direction}`
		},
        setViewMode (mode) {
            this.$store.commit('setSearchResultViewMode', mode)
        },
        onScrollBottom ($state) {
            if (this.page === this.totalPages) {
                $state.complete()
                return;
            }

            const combinedQuery = {
				...this.$route.query,
				page: this.page + 1,
                page_size: this.pageSize
			}

            DataService.searchCards(combinedQuery)
                .then((response) => {
					this.status = response.status;
                    this.data.push(...response.data.data)

                    this.total = response.data.total_cards;
					this.totalPages = response.data.total_pages;
					this.paginatedSize = response.data.paginated_cards_count;
					this.page = response.data.page;

					this.sort = response.data.sort;
					this.order = response.data.order;

                    $state.loaded();
				})
				.catch((e) => {
                    
                    $state.error()
					console.log(e);
				});
        },
		parseLabel: TextService.parseLabel,
		capitalizeFirstChar: TextService.capitalizeFirstChar,
		getOrderText: TextService.getOrderText,
        getSearchComments: TextService.getSearchComments
    },
	computed: {
		resultStart () {
			return this.page === 1 ? 1 : (this.page - 1) *  this.pageSize + 1;
		},
		resultEnd () {
			if (this.total < this.pageSize) return this.total
			return this.resultStart + this.paginatedSize - 1
		},
		paginationComment () {
			return `${this.resultStart} – ${this.resultEnd} of ${this.total} cards`
		},
        scrollPaginationComment () {
			return `1 – ${this.resultEnd} of ${this.total} cards`
		},
		// use an improved version from TextService
		searchComment () {
			let where = ''
            where = TextService.getSearchComments({
                query: this.$route.query,
                option: this.$searchOptions
                }).map(comment => comment.text).join(', ')

			if (where.length) where = `where ${where}`

			return where;
		},
        viewMode () {
			return this.$store.state.searchResultPage.viewMode
		}
	},
    mounted () {
        this.loading = true;
        this.getCards({...this.$route.query, page_size: this.pageSize} )
    },
    beforeRouteUpdate (to, from, next) {
        this.getCards({...to.query, page_size: this.pageSize})
        
		next()
	},
};
</script>

<template>
	<div class="page">
		<b-container
			v-if="data.length"
			class="page__search-result"
		>	
			<b-row>
                <slide-y-up-transition appear :duration=500 mode="out-in">
                    <b-col>
                        <h4>Cards</h4>
                    </b-col>
                </slide-y-up-transition>
			</b-row>
            <slide-x-left-transition appear :duration=500 mode="out-in">
                <b-row>
                    
                    <b-col
                        cols="12"
                        lg="6"
                        class="mb-2"
                    >
                        <div class="page__search-result__tools">
                            <div class="page__search-result__tools__view mr-3  mb-md-2 mb-lg-0">
                                <div class="mb-1 pl-1">View</div>
                                <b-button-group>
                                    <b-button
                                        :disabled="viewMode === 'gallery'"
                                        content='View cards in gallery mode' 
                                        v-tippy="{ 
                                            placement: 'bottom',
                                            arrow: true
                                        }"
                                        @click="setViewMode('gallery')"
                                    >
                                        <b-icon-grid-3x2-gap-fill shift-v="-1" />
                                    </b-button>
                                    <b-button
                                        :disabled="viewMode === 'list'"
                                        content='View cards in list mode' 
                                        v-tippy="{ 
                                            placement: 'bottom',
                                            arrow: true
                                        }"
                                        @click="setViewMode('list')"
                                    >
                                        <b-icon-list shift-v="-1" />
                                    </b-button>
                                </b-button-group>
                            </div>
                            
                            <div class="page__search-result__tools__sort">
                                <div class="mb-1 pl-1">Sort</div>
                                <b-dropdown :text="parseLabel(sort)" class="mr-2">
                                    <b-dropdown-item
                                        v-for="(option, index) in sortOptions"
                                        :key=index
                                        @click="changeSortName(option)"
                                    >
                                        {{ parseLabel(option) }}
                                    </b-dropdown-item>
                                </b-dropdown>

                                <!-- <span class="mr-1">:</span> -->
                                <b-dropdown class="">
                                    <template #button-content>
                                        <!-- {{ getOrderText(order) }} -->
                                        <b-icon :icon="getSortIcon(order)" />
                                    </template>
                                    
                                    <b-dropdown-item
                                        @click="changeSortOrder('ASC')"
                                    >
                                        <!-- Ascend -->
                                        <b-icon :icon="getSortIcon('ASC')" style="width: 25px; height: 25px"/>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        @click="changeSortOrder('DESC')"
                                    >
                                        <!-- Descend -->
                                        <b-icon :icon="getSortIcon('DESC')" style="width: 25px; height: 25px"/>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </b-col>
                    <b-col
                        
                        cols="12"
                        lg="6"
                        class="d-lg-block d-md-none d-sm-none d-none"
                    >
                        <b-pagination-nav
                            v-if="!infiniteScroll"
                            :link-gen="paginationLinkGen"
                            :number-of-pages="totalPages"
                            :value="page"
                            use-router
                            align="right"
                            style="margin-top: 26.5px"
                        />
                    </b-col>
                </b-row>
            </slide-x-left-transition>
			<b-row>
				<b-col v-if="data && data.length">
					<div class="my-3">
						{{ infiniteScroll ? scrollPaginationComment:  paginationComment }} {{ searchComment }}
					</div>
				</b-col>
                <b-col v-if="loading">
					<div class="my-3">
					</div>
				</b-col>
			</b-row>
            <b-row v-if="data && data.length">
				<b-col>
					<Catalog
						:list-mode="viewMode === 'list'"
						:data="data"
						:key="queryString"
					/>
                    
                    <infinite-loading
                        v-if="infiniteScroll"
                        :identifier="queryString"
                        @infinite="onScrollBottom"
                        :distance="10"
                        spinner="circles"
                    >
                        
                         <div class="mt-3" slot="no-more">
                            Showing all {{ scrollPaginationComment }} {{ searchComment }}.
                         </div>
                         <div class="mt-3" slot="no-results">
                         </div>
                         <div slot="error" slot-scope="{ trigger }">
                            Error loading cards, <a href="javascript:;" @click="trigger">try again.</a>
                        </div>
                    </infinite-loading>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="mt-3">
					<b-pagination-nav
                        v-if="!infiniteScroll"
						:link-gen="paginationLinkGen"
						:number-of-pages="totalPages"
						:value="page"
						use-router
						align="right"
					/>
				</b-col>
			</b-row>
			<!-- <b-row>
				<b-col>
                    <h3>Random Cards</h3>
					<Catalog list-mode random />
				</b-col>
			</b-row> -->
		</b-container>
		<b-container
			v-if="status === 204"
			class="page__search-result"
		>
			<b-row class="text-center mt-4">
				<b-col>
					<b-icon-exclamation-diamond
						class="mb-4"
						style="width: 100px; height: 100px;"
					/>
					<h3>204</h3>
					Nothing found for cards {{ searchComment }} 
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<style lang="scss">
.page {
	&__search-result {
        // extra space for card image tooltip in list mode
        margin-bottom: 250px;
		&__tools {

			&__view ,
			&__sort {
				display: inline-block;
			}
		}
	}
}
</style>
